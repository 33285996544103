<template>
  <div class="advanced-search__filter">
    <div class="filter__table-name tw-w-full">
      <div class="tw-flex tw-items-center tw-text-text-primary">
        <feather-icon
          :icon="EntityIcon[option.entityId] || 'CircleIcon'"
          class="tw-mr-3"
        />
        <span class="tw-text-xs tw-font-bold">
          {{ $t(option.tableLabel) }}
        </span>
      </div>
      <hr class="tw-my-2" />
    </div>
    <div class="filter__fields tw-py-2">
      <div
        v-for="(item, i) in option.fields"
        :key="i"
        class="tw-mb-3"
      >
        <div class="tw-flex">
          <div class="tw-w-7/12 tw-mr-2">
            <label class="tw-block">{{ $t(item.label) }}</label>
            <b-form-datepicker
              v-if="item.type === CustomFieldTypes.Date"
              :disabled="isInputDisabled(item)"
              :locale="$i18n.locale"
              :placeholder="$t(item.label)"
              :value="item.filterValue"
              @input="e => handleFilterValueChange(e, item)"
            />
            <prozess-select
              v-else-if="item.type === CustomFieldTypes.List"
              :disabled="isInputDisabled(item)"
              style="flex: 1"
              :placeholder="$t(item.label)"
              :options="item.values"
              :label="$t(item.label)"
              :value="item.filterValue"
              @input="e => handleFilterValueChange(e, item)"
            />
            <b-form-input
              v-else
              :type="getInputType(item)"
              :disabled="isInputDisabled(item)"
              :placeholder="$t(item.label)"
              :value="item.filterValue"
              @input="e => handleFilterValueChange(e, item)"
            />
          </div>
          <div class="tw-w-5/12">
            <label class="tw-block">{{ $t('Condition') }}</label>
            <prozess-select
              class="filter__condition"
              :append-to-body="false"
              :searchable="false"
              :clearable="false"
              :options="getConditionOptions(item)"
              :value="getInitialValue(item.filterCondition)"
              @input="e => handleFilterConditionChange(e, item)"
            >
              <template #selected-option="{ label }">
                <span class="tw-text-text-primary">{{ label }}</span>
              </template>
              <template #option="{ label }">
                <span class="tw-text-text-primary">{{ label }}</span>
              </template>
            </prozess-select>
          </div>
        </div>
        <div class="tw-flex">
          <div class="tw-w-7/12 tw-mr-2 text-danger tw-text-2xs tw-mt-1">
            {{ $t(getError(item, 'input')) }}
          </div>
          <div class="tw-w-5/12 text-danger tw-text-2xs tw-mt-1">
            {{ $t(getError(item, 'condition')) }}
          </div>
        </div>
        <div
          v-if="item.filterCondition === AdvancedSearchCondition.ILIKE"
          class="tw-w-full text-muted tw-text-2xs tw-mt-1"
        >
          {{ $t('ilike-helper-text') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import { EntityIcon } from '@/constants/app'
import { AdvancedSearchCondition, nullableConditions } from '@/constants/advancedSearch'
import { getInputType } from '@/helpers/customFields'
import CustomFieldTypes from '@/constants/customFieldTypes'

export default {
  props: {
    entityId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      AdvancedSearchCondition,
      EntityIcon,
      CustomFieldTypes,
      conditionOptions: Object.keys(AdvancedSearchCondition).map(key => ({
        label: AdvancedSearchCondition[key],
        value: key,
      })),
    }
  },

  computed: {
    ...mapGetters({
      filterOptions: 'advancedSearch/filterOptions',
    }),

    ...mapState({
      errors: ({ advancedSearch }) => advancedSearch.errors,
    }),

    option() {
      return this.filterOptions.find(({ entityId }) => entityId === this.entityId)
    },
  },

  methods: {
    ...mapMutations({
      setFilterValue: 'advancedSearch/SET_FILTER_VALUE',
      setFilterCondition: 'advancedSearch/SET_FILTER_CONDITION',
    }),

    getConditionOptions(field) {
      const { IS_TRUE, IS_NOT_TRUE } = AdvancedSearchCondition
      const isTrueOrFalse = label => [IS_TRUE, IS_NOT_TRUE].includes(label)

      return field.type === CustomFieldTypes.Bool
        ? this.conditionOptions.filter(({ label }) => isTrueOrFalse(label))
        : this.conditionOptions.filter(({ label }) => !isTrueOrFalse(label))
    },

    getError(item, type) {
      return this.$hasError(`${this.option.schema}_${this.option.key}_${item.key}_${type}`)
    },

    getInitialValue(value) {
      return AdvancedSearchCondition[value]
    },

    getInputType,

    handleFilterValueChange(filterValue, field) {
      this.setFilterValue({
        filterValue,
        entityId: this.entityId,
        fieldKey: field.key,
      })
    },

    handleFilterConditionChange({ value: filterCondition }, field) {
      if (nullableConditions.includes(AdvancedSearchCondition[filterCondition])) {
        this.setFilterValue({
          filterValue: null,
          entityId: this.entityId,
          fieldKey: field.key,
        })
      }

      this.setFilterCondition({
        filterCondition,
        entityId: this.entityId,
        fieldKey: field.key,
      })
    },

    isInputDisabled({ filterCondition, type }) {
      const { IS_NULL, IS_NOT_NULL } = AdvancedSearchCondition

      return [IS_NULL, IS_NOT_NULL].includes(AdvancedSearchCondition[filterCondition]) || type === CustomFieldTypes.Bool
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.advanced-search__filter {
  .filter__condition {
    .vs__search {
      position: absolute;
    }

    .vs__dropdown-toggle {
      min-height: 2.714rem;
    }

    .vs__dropdown-menu {
      min-width: unset;
    }
  }

  .b-form-datepicker label.form-control {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
